export default defineNuxtRouteMiddleware((to, from) => {
  const { data } = useAuth()
  const user = data.value?.user || { role: 'user' }
  const canAccess = user.role === 'admin' || user.role === 'owner'
  const protectedRoutes = ['/settings/billing', '/settings/users']

  if (protectedRoutes.includes(to.fullPath) && !canAccess) {
    return abortNavigation()
  }
})
